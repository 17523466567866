
  
  .autocomplete {
    position: absolute;
    padding: 1em 1em 0;
    z-index: 999;
  }
  
  .people {
    width: 100%;
    max-width: 20rem;
    font-size: 17px;
    list-style: none;
    background-color: white;
    padding: 0;
    border: solid 1px gray;
    margin: 0 auto;
    color: unset;

  }
  
  .people li a {
    display: block;
    padding: 5px;
    outline: none;
    color: unset;
    text-decoration: unset;
  }
  
  .people li a:focus {
    background-color: lightblue;
  }
  .cursor-pointer input {
    cursor: pointer;
  }