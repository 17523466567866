.label {
    margin-Right: 10px;
    font-weight: bold;
    margin-Bottom: unset;
    font-size: 14px;
}

.list-inline {
    display: inline-block;
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.list-inline>li> button {
    background-color: #500089;
    color: #fff;
    font-weight: bold;
    border-color: #500089;
    height: 41px;
    border-radius: 25px;
}

.list-inline>li> button:hover {
    background-color: #fff;
    color: #500089;
    font-weight: bold;
    border-color: #500089;
    height: 41px;
    border-radius: 25px;
}

.pay-lists:hover {
    color: #1976d2 !important;
}