.invoice-box {
    /* margin: 10px auto 30px; */
    /* border: 1px solid #d3d3d3; */
    width: 595px;
    /* height: 842; */
    /* max-width: 400px; */
    display: block;
}

.invoice-box1 {
    margin: 10px auto 30px;
    /* border: 1px solid #d3d3d3; */
    width: 100%;
    max-width: 600px;
    display: block;
}
.invoice-inside {
    /* background: #ffffff; */
    padding: 20px;
    z-index: 20;
    /* box-shadow: 0 0 1px 0 #ccc; */
    /* -webkit-box-shadow: 0 0 1px 0 #ccc; */
    /* -moz-box-shadow: 0 0 1px 0 #ccc; */
    border-radius: 10px;
    min-width: 200mm;
    min-height: 287mm;
    
}

.invoicetable tbody tr td{
    font-size: 12px;
    border:1px solid #000000;
    border-top-style: hidden;
}
.invoicetable{
  border-collapse: collapse;
  border-top: 1px;
  border-bottom: 1px;
  border-right: 1px;
  border-left: 1px;
  

}

.padbot{
    padding-top: 0 !important;
     padding-bottom: 0 !important
}
.rowbody{
  height: 37px ;
  position: relative;
  border-bottom: none !important;
  border-bottom-style: hidden !important;
}

.custom-tbody {
    /* min-height: 100%;  */
    position: relative;
    overflow-y: auto; 
    /* display: block; */
    height: 555px;
    /* min-height:500px !important; */
    margin-top: 1px solid #000;
  }
  .table-container {
    position: relative;
    page-break-inside: avoid;
    page-break-after: auto;
  }

  
  .col1{
    margin-top: 20px;
  }
  .printdiv{
    /* font-family: 'Segoe UI', sans-serif; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }
  
  .set12{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }

 
