.invoice-bx {
    margin: 10px auto 30px;
    border: 1px solid #d3d3d3;
    width: 600px;
    max-width: 1000px;
    display: block;
}


.invoice-insd {
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 0 1px 0 #ccc;
    -webkit-box-shadow: 0 0 1px 0 #ccc;
    -moz-box-shadow: 0 0 1px 0 #ccc;
    border-radius: 10px;
}