.prod-tab{
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.prod-tab1{
    border-bottom: 1px solid #1976d2;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #1976d2;
    cursor: pointer;
}

.prod-tab:hover{
    border-bottom: 1px solid #1976d2;
    color: #1976d2 !important;
}

.productbatchborder{
    border: 1px solid gray;
    padding: 10px;
    margin-top: 40px;
}

.imageDeletebtn {
    width: 100%;
    font-size: 10px!important;
}

.productImagelist {
    width: 100%;
    height: 50px;
}

.cardimage {
    position: relative;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    width: 70px;
    margin-right: 10px;
}

.rmsc .dropdown-content {
    z-index: 2000!important;
}

.barcodeimg{
    height: 100px !important
}

.barcodeimg svg {
    width: 174px !important;
    height: 80px !important;
    margin-left: 1px !important;
}

.prostable{
    position: sticky ;
    right:0;
    z-index:1;
    background-color: #fff;
}

.prostable2{
    position:sticky;
    right:75px;
    z-index:1;
    background-color: #fff;
}