.setting-para {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
} 

.spinner {
    animation: spin infinite 5s linear;
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-grid-horizontal line{
  stroke: unset !important;
}
.recharts-cartesian-grid-vertical line {
  stroke: unset !important;
}
.recharts-line path{
  stroke-width: 3 !important
}