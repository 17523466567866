.balance-table{
    font-weight: bold;
    font-size: 14px !important;
}

.pandl{
    width: 50% !important;
    border: 1px solid #d3d3d3;
    border-bottom: unset;
    border-top: unset;
}
.pandlh{
    width: 50% !important;
    border: 1px solid #d3d3d3;
}

.pandlb{
    width: 50% !important;
    border: 1px solid #d3d3d3;
    border-top: unset;
}

/* .ledger-table{
    max-height: 250px;
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}
.ledger-tableh{
    flex: 0 0 auto;
}
.ledger-tabletr{
    width: 100%;
    display: table;
    table-layout: fixed;
}
table {
    display: flex;
    flex-flow: column;
    width: 100%;
} */