.stock-prev{
    border: 1px solid #000 !important;
}

.dataResult {
    width: 100%;
    max-height: 350px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  .searchItem {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }