.label {
    margin-Right: 10px;
    font-weight: bold;
    margin-Bottom: unset;
    font-size: 14px;
}

.list-inline {
    display: inline-block;
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.list-inline>li> button {
    background-color: #500089;
    color: #fff;
    font-weight: bold;
    border-color: #500089;
    height: 41px;
    border-radius: 25px;
}

.list-inline>li> button:hover {
    background-color: #fff;
    color: #500089;
    font-weight: bold;
    border-color: #500089;
    height: 41px;
    border-radius: 25px;
}


  
.autocomplete {
    position: absolute;
    padding: 1em 1em 0;
    z-index: 999;
  }
  
  .people {
    width: 100%;
    max-width: 20rem;
    font-size: 17px;
    list-style: none;
    background-color: white;
    padding: 0;
    border: solid 1px gray;
    margin: 0 auto;
    color: unset;

  }
  
  .people li a {
    display: block;
    padding: 5px;
    outline: none;
    color: unset;
    text-decoration: unset;
  }
  
  .people li a:focus {
    background-color: lightblue;
  }